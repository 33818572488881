<template>
  <div class="screenx row" style="margin-top:90px; margin-bottom:200px;">

    <div class="offset-12 col-12 offset-xs-12 col-xs-12 offset-sm-12 col-sm-12 offset-md-12 col-md-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">

      <div class="row py-5">
        <div class="col-8 offset-2 mb-5 lead text-center">
          <div class="mb-4 text-center display-5" v-html="page.title"></div>
          <button
            v-if="page.code"
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3 mt-4"
            v-html="'Learn more about ' + page.code.toUpperCase()"
            @click="urlRedirect('https://adamsws.com')"
          ></button>
        </div>

        <div class="col-8 offset-2 mb-5 lead text-centerx">
          <p class="mb-2"
            v-for="para in page.details"
            v-bind:key="para['.key']"
            v-html="para"
          >
          </p>
        </div>

        <div
          class="col-12 offset-2x"
          v-for="(para, p) in page.points"
          v-bind:key="para['.key']"
        >
          <div class="row py-3">
            <div
              class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5"
              v-if="(p % 2) == 0"
            >
              <img
                v-if="para.image"
                :src="para.image"
                style="width:100% !important;"
              />
            </div>
            <div
              class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 lead"
            >
              <div class="mt-5">
                <p class="lead fw-bold" v-html="para.title"></p>
                <p class="lead" v-html="para.details"></p>
              </div>
            </div>
            <div
              class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5"
              v-if="(p % 2) > 0"
            >
              <img
                v-if="para.image"
                :src="para.image"
                style="width:100% !important;"
                class=""
              />
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'Products',
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    urlRedirect (url) {
      if ([null, undefined].includes(url)) { return }
      location.href = url
    }
  },
  data () {
    return {
      page: {
        code: 'ADAMS',
        title: 'Aviation Data Administration Management System',
        tagline: 'All your Civil Aviation Data needs in one place',
        details: [
          'In a bid to help our clients automate their civil aviation processes in compliance with international standards, we provide a range of safety oversight tools, designed to record, evaluate and analyse Aviation Data in Real Time.',
          'Incorporate analytical graphs and tools for recorded office and regional safety oversight data: Work Activities, Training Data, Foreign Aircraft Suriveillance Information, PEL, Aircraft, Accident Data and Risk Analysis'
        ],
        points: [
          // ADAMS is ...
          {
            image: 'https://adamsws.com/static/adams_home_page-d74162e20c47addea02b537a708dfbf1.png',
            title: 'A fully functional Control Resource Management (CRM) Platform for Civil Aviation',
            details: 'Designed to record and evaluate Aviation Data in Real Time (Extensive Management Reports). Designed to comply with international standards, policies and procedures (ICAO, FAA, EASA)'
          },
          {
            image: 'https://adamsws.com/static/adams_home_page_dash-747ddc559e372fc75f5da09b9cf8fe55.png',
            title: 'Comprehensive Dashboards',
            details: 'Designed to present up to date safety oversight information and reports to the end user at an implementing office. Designed to cater for Critical Elements 4, 5, 6, 7 and 8 sections of the PQs requirements during international safety oversight audits (USOAPS/ICVM)'
          },
          {
            image: 'https://adamsws.com/static/adams-work-risk-analysis-38746723874hfe34h845gg32ouha8v3ju4.png',
            title: 'Safety Oversight Data and Trend Analysis',
            details: 'ADAMS incorporates analytical graphs and tools for recorded office and regional safety oversight data: Work Activities, Training Data, Foreign Aircraft Suriveillance Information, PEL, Aircraft, Accident Data and Risk Analysis'
          },
          {
            image: 'https://adamsws.com/static/adams_single_user_profile_mini-ebf9e4448804805a3512ed0c96b23552.png',
            title: 'Manage safety oversight inspector accounts',
            details: 'With the ADAMS account, each inspector is provided access to the platform based on their responsibilities. The ADAMS account ensures that inspectors have access to certain sections of the system and to certain specialties as approved by management.'
          }
          /*
          ,
          {
            title: 'Every relevant personnel detail is documented',
            details: 'Manage personnel education, work history, licence and medical Certitificate history as well. This information will be relevant for the Human Resources department.'
          }
          */
        ]
      },
      products: [
      ]
    }
  }
}
</script>
